import { default as createefyHPU1ZJZMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/cash-transfers/create.vue?macro=true";
import { default as _91id_93lZ1ZJmgNVWMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/channel-settlement-snapshots/[id].vue?macro=true";
import { default as createVOg28kwyLqMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/channel-settlement-snapshots/create.vue?macro=true";
import { default as indexELbqGddsBCMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/channel-settlement-snapshots/index.vue?macro=true";
import { default as close1qmh5qhqhDMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/channel-settlements/close.vue?macro=true";
import { default as createnr9C2cgIzCMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/channel-settlements/create.vue?macro=true";
import { default as indexcivCmq7n19Meta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/index.vue?macro=true";
import { default as login1dTMk8mWYsMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/login.vue?macro=true";
import { default as _91id_93ey56EjrNfkMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/orders/[id].vue?macro=true";
import { default as createLYfDimUch7Meta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/orders/create.vue?macro=true";
import { default as indextXA0iwOQxNMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/orders/index.vue?macro=true";
import { default as print_45jobsWf8U08ycDBMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/print-jobs.vue?macro=true";
import { default as index77dwgDdNFqMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/reservations/index.vue?macro=true";
import { default as lanesw7Zg4Re8N9Meta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/reservations/lanes.vue?macro=true";
import { default as setupMZyLYg71aAMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/setup.vue?macro=true";
import { default as editCYe7xAxhlZMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/stock-statuses/[id]/edit.vue?macro=true";
import { default as indexgvNFAwBnWfMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/stock-statuses/[id]/index.vue?macro=true";
import { default as createGsAFxN4KpMMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/stock-statuses/create.vue?macro=true";
import { default as index2w1UCYXONcMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/stock-statuses/index.vue?macro=true";
import { default as stock_45statuses3gP8cYB84QMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/stock-statuses.vue?macro=true";
import { default as index4AQVXQSw4cMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/tables/[tableId]/orders/[orderId]/index.vue?macro=true";
import { default as splitX7WpVuPyQhMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/tables/[tableId]/orders/[orderId]/split.vue?macro=true";
import { default as _91orderId_93KBkKZQDohlMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/tables/[tableId]/orders/[orderId].vue?macro=true";
import { default as createrca3mvUYQPMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/tables/[tableId]/orders/create.vue?macro=true";
import { default as _91tableId_93YbgoMl298kMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/tables/[tableId].vue?macro=true";
import { default as indexKXNnRcJ4VeMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/tables/index.vue?macro=true";
import { default as indexKmluvW8qe2Meta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/takeaway-orders/[id]/index.vue?macro=true";
import { default as splitegVy8SokqBMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/takeaway-orders/[id]/split.vue?macro=true";
import { default as _91id_93qqatoyfQG4Meta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/takeaway-orders/[id].vue?macro=true";
import { default as createdfLiDzfM9cMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/takeaway-orders/create.vue?macro=true";
import { default as index93vPVju7W8Meta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/takeaway-orders/index.vue?macro=true";
export default [
  {
    name: "cash-transfers-create",
    path: "/cash-transfers/create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/cash-transfers/create.vue").then(m => m.default || m)
  },
  {
    name: "channel-settlement-snapshots-id",
    path: "/channel-settlement-snapshots/:id()",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/channel-settlement-snapshots/[id].vue").then(m => m.default || m)
  },
  {
    name: "channel-settlement-snapshots-create",
    path: "/channel-settlement-snapshots/create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/channel-settlement-snapshots/create.vue").then(m => m.default || m)
  },
  {
    name: "channel-settlement-snapshots",
    path: "/channel-settlement-snapshots",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/channel-settlement-snapshots/index.vue").then(m => m.default || m)
  },
  {
    name: "channel-settlements-close",
    path: "/channel-settlements/close",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/channel-settlements/close.vue").then(m => m.default || m)
  },
  {
    name: "channel-settlements-create",
    path: "/channel-settlements/create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/channel-settlements/create.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login1dTMk8mWYsMeta || {},
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders-create",
    path: "/orders/create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/orders/create.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "print-jobs",
    path: "/print-jobs",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/print-jobs.vue").then(m => m.default || m)
  },
  {
    name: "reservations",
    path: "/reservations",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/reservations/index.vue").then(m => m.default || m)
  },
  {
    name: "reservations-lanes",
    path: "/reservations/lanes",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/reservations/lanes.vue").then(m => m.default || m)
  },
  {
    name: "setup",
    path: "/setup",
    meta: setupMZyLYg71aAMeta || {},
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: stock_45statuses3gP8cYB84QMeta?.name,
    path: "/stock-statuses",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/stock-statuses.vue").then(m => m.default || m),
    children: [
  {
    name: "stock-statuses-id-edit",
    path: ":id()/edit",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/stock-statuses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-id",
    path: ":id()",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/stock-statuses/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-create",
    path: "create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/stock-statuses/create.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses",
    path: "",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/stock-statuses/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables-tableId",
    path: "/tables/:tableId()",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/tables/[tableId].vue").then(m => m.default || m),
    children: [
  {
    name: _91orderId_93KBkKZQDohlMeta?.name,
    path: "orders/:orderId()",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/tables/[tableId]/orders/[orderId].vue").then(m => m.default || m),
    children: [
  {
    name: "tables-tableId-orders-orderId",
    path: "",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/tables/[tableId]/orders/[orderId]/index.vue").then(m => m.default || m)
  },
  {
    name: "tables-tableId-orders-orderId-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/tables/[tableId]/orders/[orderId]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables-tableId-orders-create",
    path: "orders/create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/tables/[tableId]/orders/create.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables",
    path: "/tables",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/tables/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qqatoyfQG4Meta?.name,
    path: "/takeaway-orders/:id()",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/takeaway-orders/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "takeaway-orders-id",
    path: "",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/takeaway-orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders-id-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/takeaway-orders/[id]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "takeaway-orders-create",
    path: "/takeaway-orders/create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/takeaway-orders/create.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders",
    path: "/takeaway-orders",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241004181629/pages/takeaway-orders/index.vue").then(m => m.default || m)
  }
]